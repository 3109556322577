import React from "react";
import Head from "../../components/head.js";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import Layout from "../../components/layout";

const Alvaro = () => {
  return (
    <Layout>
      <Head title="Alvaro Sánchez" />
      <h3>Alvaro Sánchez</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Guatemala City
      </h4>
      <p>
        <OutboundLink href="https://www.instagram.com/sanchezisdead/">
          Instagram
        </OutboundLink>
      </p>
      {/* <p>
        <OutboundLink href="https://ello.co/sanchezisdead">Ello</OutboundLink>
      </p> */}
      <p>
        He has collaborated and published with art, literature and design
        magazines from Guatemala and other nations around the world. His work
        has been exhibited in countries in Europe, Asia and America. He has
        published the book Mañana Muerta de Domingo 1st edition (Editorial X).
        And he maintains a fortnightly column in the newspaper where he writes
        about music called Reducto Sónico
      </p>
      {/* <img
        src="https://assets2.ello.co/uploads/asset/attachment/15397112/ello-optimized-fc0b83e8.jpg"
        alt="Alvaro Sánchez art"
      />
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/15397109/ello-optimized-408fe05d.jpg"
        alt="Alvaro Sánchez art"
      />
      <p style={{ marginBottom: 0 }}>CINCO UNGIDOS</p>
      <p>2022</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15347239/ello-optimized-1b28204b.jpg"
        alt="Alvaro Sánchez art"
      />
      <p style={{ marginBottom: 0 }}>UN REY PROMETIDO</p>
      <p>2022</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/14121968/ello-optimized-440f78de.jpg"
        alt="Alvaro Sánchez art"
      />
      <p>FIGURA CON TIJERAS</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets1.ello.co/uploads/asset/attachment/4065602/ello-optimized-2478f19a.jpg"
        alt="Alvaro Sánchez art"
      />
      <p style={{ marginBottom: 0 }}>PARADOJA</p>
      <p>2015</p>
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/15297882/ello-optimized-1ff9a31c.jpg"
        alt="Alvaro Sánchez art"
      />
      <p style={{ marginBottom: 0 }}>EL HOMBRE QUE CUBRE AL MUNDO</p>
      <p>2022</p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/11537131/ello-optimized-c4109d6f.jpg"
        alt="Alvaro Sánchez art"
      />
      <img
        style={{ marginBottom: 5 }}
        src="https://assets2.ello.co/uploads/asset/attachment/15229266/ello-optimized-8bc407c3.jpg"
        alt="Alvaro Sánchez art"
      />
      <p style={{ marginBottom: 0 }}>FLORES QUE BROTAN DE LA CARNE</p>
      <p>2022</p>
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/11203270/ello-optimized-e0e470a0.jpg"
        alt="Alvaro Sánchez art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/9387851/ello-optimized-e9d4983a.jpg"
        alt="Alvaro Sánchez art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/10130282/ello-optimized-053b6b3e.jpg"
        alt="Alvaro Sánchez art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/8419561/ello-optimized-860adf0e.jpg"
        alt="Alvaro Sánchez art"
      />
      <img
        style={{ marginBottom: 5 }}
        src="https://assets0.ello.co/uploads/asset/attachment/15306074/ello-optimized-0ac4f5ca.jpg"
        alt="Alvaro Sánchez art"
      />
      <p style={{ marginBottom: 0 }}>DESTROYED APOLLO</p>
      <p>2022</p> */}
    </Layout>
  );
};

export default Alvaro;
